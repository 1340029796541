import { formatWhatsAppUrl } from 'src/utils/formatters'

export const MAX_PROFILE_PICTURE_SIZE = '3 MB'

export const acceptedImageFileTypes = ['image/jpeg', 'image/webp', 'image/png']

export const monthsArray = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]

export const smallMonthsArray = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez'
]

export const statesOptions = [
  {
    abbr: 'AC',
    name: 'Acre'
  },
  {
    abbr: 'AL',
    name: 'Alagoas'
  },
  {
    abbr: 'AP',
    name: 'Amapá'
  },
  {
    abbr: 'AM',
    name: 'Amazonas'
  },
  {
    abbr: 'BA',
    name: 'Bahia'
  },
  {
    abbr: 'CE',
    name: 'Ceará'
  },
  {
    abbr: 'DF',
    name: 'Distrito Federal'
  },
  {
    abbr: 'ES',
    name: 'Espírito Santo'
  },
  {
    abbr: 'GO',
    name: 'Goiás'
  },
  {
    abbr: 'MA',
    name: 'Maranhão'
  },
  {
    abbr: 'MT',
    name: 'Mato Grosso'
  },
  {
    abbr: 'MS',
    name: 'Mato Grosso do Sul'
  },
  {
    abbr: 'MG',
    name: 'Minas Gerais'
  },
  {
    abbr: 'PA',
    name: 'Pará'
  },
  {
    abbr: 'PB',
    name: 'Paraíba'
  },
  {
    abbr: 'PR',
    name: 'Paraná'
  },
  {
    abbr: 'PE',
    name: 'Pernambuco'
  },
  {
    abbr: 'PI',
    name: 'Piauí'
  },
  {
    abbr: 'RJ',
    name: 'Rio de Janeiro'
  },
  {
    abbr: 'RN',
    name: 'Rio Grande do Norte'
  },
  {
    abbr: 'RS',
    name: 'Rio Grande do Sul'
  },
  {
    abbr: 'RO',
    name: 'Rondônia'
  },
  {
    abbr: 'RR',
    name: 'Roraima'
  },
  {
    abbr: 'SC',
    name: 'Santa Catarina'
  },
  {
    abbr: 'SP',
    name: 'São Paulo'
  },
  {
    abbr: 'SE',
    name: 'Sergipe'
  },
  {
    abbr: 'TO',
    name: 'Tocantins'
  }
]

export const defaultAddress = {
  cep: '',
  country: '',
  state: '',
  city: '',
  neighborhood: '',
  street: '',
  number: '',
  complement: '',
  reference: ''
}

export const STATUS_SINGLE = 'Solteiro'
export const STATUS_MARRIED = 'Casado'
export const STATUS_SEPARATED = 'Separado'
export const STATUS_DIVORCED = 'Divorciado'
export const STATUS_WIDOWED = 'Viúvo'

export const maritalStatus = [
  STATUS_SINGLE,
  STATUS_MARRIED,
  STATUS_SEPARATED,
  STATUS_DIVORCED,
  STATUS_WIDOWED
]

export const booleanOptions = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false }
]

export const PHONE_ATENDIMENTO_GERAL = '5527996947753'
export const PHONE_ATENDIMENTO_CONCIERGE = '5527999181373'
export const PHONE_COMERCIAL = '5527997799448'
export const PHONE_ASSESSORIA = '5527996541154'

const redirectAtendimentoWhatsapp = (message = '', isConcierge = false) => {
  const phone = isConcierge ? PHONE_ATENDIMENTO_CONCIERGE : PHONE_ATENDIMENTO_GERAL

  return formatWhatsAppUrl(phone, message)
}

export const serviceWhatsappUrl = (isConcierge) => redirectAtendimentoWhatsapp('Olá, estou com algumas dúvidas. Vocês podem me ajudar?', isConcierge)

export const notificationsSupportUrl = (isConcierge) => redirectAtendimentoWhatsapp('Olá, estou com dificuldades para ativar as notificações no App da Docstage.', isConcierge)

export const complianceWhatsappUrl = (isConcierge) => redirectAtendimentoWhatsapp('Olá, gostaria de suporte sobre algumas questões jurídicas.', isConcierge)

export const serviceWhatsappNotificationsHelpUrl = (isConcierge) => redirectAtendimentoWhatsapp('Olá, gostaria de ajustar algumas notificações da Docstage. Vocês podem me ajudar?', isConcierge)

export const commercialWhatsappUrl = formatWhatsAppUrl(PHONE_COMERCIAL, 'Olá, estou com algumas dúvidas no app da Docstage')

export const financialAdvisoryWhatsappUrl = formatWhatsAppUrl(PHONE_ASSESSORIA, 'Olá, gostaria de conversar sobre Assessoria Patrimonial e Financeira.')

export const CENTRAL_DE_AJUDA_URL = 'https://ajuda.docstage.com.br/hc/pt-br'

export const asaasSecondCopy = 'https://www.asaas.com/segunda-via'

export const downloadRequestDataFormUrl = 'https://docstage-public.s3.sa-east-1.amazonaws.com/DOCSTAGE+-+Formula%CC%81rio+do+Exerci%CC%81cio+do+Direito+do+Titular+-+2022.06.10+-+MMadv-1.docx'

export const YEAR_OF_LAUNCH = 2022

export const BANK_CORA_URL = 'https://lp.cora.com.br/coraliados?code=docstage-servicos-contabeis&n=Docstage%20Servicos%20Contabeis'

export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const romanPattern =
  /^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/

export const DEFAULT_ROWS_PER_PAGE = 50
